$backgroundColor: #000;
$marginColor: #000;
$bodyColor: #ccc;
$bodyFont: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";

body {
  background: $marginColor;
  color: $bodyColor;
  font-family: $bodyFont;
  margin: 0px;
}

div#body {
  background: $backgroundColor;
  height: 100%;
  width: 851px;
  padding: 5px;
  max-width: 851px;
  margin: auto;
}

a {
  color: #fff
}
